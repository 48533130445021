<template>
<div class="news-detail">
<com-header @clickMenu="clickMenu" :active_index="5"></com-header>
<div class="page-breadcrumb">
<span>当前位置：</span>
<router-link class="router-link" to="/">8455线路检测中心</router-link>
<span class="arrow-right">></span>
<router-link class="router-link" to="/news-list">新闻资讯</router-link>
<span class="arrow-right">></span>
<span class="current-route">新闻详情</span>
</div>
<div class="split-line"></div>
<div class="content" v-if="news_detail.news_id">
<div class="title">{{ news_detail.title }}</div>
<div class="line">
<span class="date">发布时间：{{ news_detail.created_at }}</span>
<span class="read-num">浏览次数：{{ news_detail.views || 0 }}次</span>
</div>
<div class="main-content">
<div v-html="news_detail.content"></div>
</div>
<div class="split-line"></div>
<div class="footer-pagation">
<div
          class="prev-news"
          v-if="prev && prev.news_id"
          @click="goPrevOrNext(prev)"
        >
          上一篇：<span>{{ prev.title }}</span>
</div>
<div
          class="next-news"
          v-if="next && next.news_id"
          @click="goPrevOrNext(next)"
        >
          下一篇：<span>{{ next.title }}</span>
</div>
</div>
</div>
<div class="news-empty" v-else>
<el-empty description="暂无新闻~"></el-empty>
</div>
<com-footer @clickMenu="clickMenu"></com-footer>
</div>
</template>
<script>
import ComHeader from "@/components/layout/ComHeader";
import ComFooter from "@/components/layout/ComFooter";
import { getNewsDetail } from "@/api/home.js";

export default {
  data() {
    return {
      news_detail: {},
      prev: {},
      next: {},
    };
  },
  components: {
    ComHeader,
    ComFooter,
  },
  mounted() {
    this.getDetail();
  },
  watch: {
    "$route.query": {
      handler(newVal) {
        if (newVal) {
          this.getDetail();
        }
      },
    },
  },
  methods: {
    clickMenu(e) {
      localStorage.setItem("menu_info", JSON.stringify(e));
      this.$router.push({ name: "home" });
    },
    //获取详情数据
    getDetail() {
      const { news_id } = this.$route.query;
      getNewsDetail({ news_id }).then((res) => {
        let {
          next_news,
          prev_news,
          content,
          created_at,
          title,
          news_id,
          views,
        } = res.data.data;
        created_at = created_at.split(" ")[0] || "--";
        this.news_detail = { content, created_at, title, news_id, views };
        this.next = next_news || {};
        this.prev = prev_news || {};
      });
    },
    //跳转至上一条或下一条
    goPrevOrNext(item) {
      const { news_id, type, url } = item;
      if (type == 2) {
        window.open(url, "_blank");
      } else {
        this.$router.push({ name: "news-detail", query: { news_id } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.news-detail {
  min-height: 100vh;
  .page-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
    padding-top: 140px;
    text-align: left;
    font-size: 14px;
    color: #808080;
    box-sizing: content-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .router-link {
      font-size: 14px;
      color: #808080;
      &:hover {
        color: #22834c;
      }
    }
    .arrow-right {
      margin: 0 8px;
    }
    .current-route {
      color: #22834c;
    }
  }
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 60px;
    .title {
      height: 42px;
      font-size: 30px;
      font-weight: 600;
      color: #201f1f;
      line-height: 42px;
      text-align: center;
    }
    .line {
      height: 20px;
      font-size: 14px;
      color: #a5a5a5;
      line-height: 20px;
      margin-top: 20px;
    }
    .date {
      margin-right: 30px;
    }
    .main-content {
      width: 100%;
      min-height: 500px;
      padding: 40px 0 60px 0;
      text-align: left;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .split-line {
    max-width: 1200px;
    margin: 0 auto;
    height: 1px;
    background: #a9c0b3;
  }
  .footer-pagation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .prev-news,
    .next-news {
      height: 20px;
      font-size: 14px;
      color: #808080;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        span {
          color: #22834c;
        }
      }
    }
  }
  .news-empty {
    padding-top: 140px;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
